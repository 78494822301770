/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_APPLICANT } from "../../../core/graphql/applicants.graphql";
import { IJob } from "../../../core/interfaces/job.interface";
interface IProps {
    openModal: boolean;
    job: IJob | undefined;
    closeModal: () => void;
}

const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

const ApplicationSchema = Yup.object().shape({
    firstname: Yup.string().required("Required."),
    mi: Yup.string().max(1, "Must be at most 1 character."),
    lastname: Yup.string().required("Required."),
    phone: Yup.string()
        .required("Required.")
        .test("len", "Invalid number", (val) => {
            if (val) {
                return val.toString().length === 14;
            }

            return false;
        }),
    email: Yup.string().email("Invalid email provided.").required("Required."),
    previousEmployerName: Yup.string(),
    previousEmployerPhone: Yup.string().test("len", "Invalid number", (val) => {
        if (val) {
            return val.toString().length === 14;
        }

        return true;
    }),
    workExperience: Yup.string().required("Required."),
    workKnowledge: Yup.string().required("Required."),
    englishProficiency: Yup.string().required("Required."),
    levelOSHA: Yup.string().required("Required."),
    hasOwnTools: Yup.bool().required("Required."),
    hasSteelBoots: Yup.bool().required("Required."),
    meetMinimumRequired: Yup.bool().required("Required."),
    atLeast18: Yup.bool().oneOf([true], "Must be Yes.").required("Required."),
});

const proficiencyText = [
    'Fluent in reading, writing, and speaking English language',
    'Can understand and communicate general directions in English',
    'Understands 100 or more nouns and verbs in the English Language',
    'Poor understanding of English language, not capable of communication without the assistance of interpreter or device',
];

const ApplicantForm: FC<IProps> = (props) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const { openModal, job, closeModal } = props;

    const [proficiency, setProficiency] = useState("");

    // GRAPHQL
    const [createApplicant, { data, loading, error }] =
        useMutation(CREATE_APPLICANT);

    const onSubmit = (values: any, { resetForm }: any) => {
        const regex = /[()-] */g;

        const finalBody = {
            ...values,
            phone: values.phone?.replace(regex, ""),
            previousEmployerPhone: values.previousEmployerPhone?.replace(regex, "" ),
            hasOwnTools: values.hasOwnTools === "true",
            hasSteelBoots: values.hasSteelBoots === "true",
            meetMinimumRequired: values.meetMinimumRequired === "true",
            atLeast18: values.atLeast18 === "true",
        };

        if (job) {
            createApplicant({
                variables: {
                    input: { ...finalBody, job: job.id },
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            toast.success("Applied successfully.");
            closeModal();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            toast.error("Error applying, please try later.");
        }
    }, [error]);

    const customStyles: Modal.Styles = {
        content: {
            padding: 0,
            width: windowSize.current[0] > 800 ? "75%" : "auto",
            margin: "auto",
            backgroundColor: "transparent",
            border: "none",
        },
        overlay: {
            zIndex: 10001,
            overflowY: "auto",
            width: "100%",
        },
    };

    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            onRequestClose={closeModal}
            contentLabel="Modal"
        >
            <section className="">
                <Formik
                    initialValues={{
                        firstname: "",
                        mi: "",
                        lastname: "",
                        phone: "",
                        email: "",
                        previousEmployerName: "",
                        previousEmployerPhone: "",
                        workExperience: "",
                        workKnowledge: "",
                        meetMinimumRequired: "",
                        atLeast18: "",
                        englishProficiency: "",
                        levelOSHA: "",
                        hasOwnTools: "",
                        hasSteelBoots: ""
                    }}
                    onSubmit={onSubmit}
                    validationSchema={ApplicationSchema}
                >
                    {({ setFieldValue }) => (
                        <Form>
                            <div className="bg-fu-dark-gray w-full md:w-4/4 mx-auto text-white p-10">
                                <div className="text-xl font-Gentium uppercase  tracking-wide">
                                    Application Form
                                </div>

                                <div className="border-b border-white my-4"></div>
                                <div className="text-m flex flex-col md:flex-row gap-8 md:gap-16">
                                    <div className="space-y-8 w-full md:w-2/4">
                                        <div className="flex flex-col md:flex-row gap-5">
                                            <div>
                                                <Field name="firstname" className={"form-input"} placeholder="First Name" />
                                                <ErrorMessage name="firstname" component="div" className="text-sm text-red-700 pl-4" />
                                            </div>
                                            <div>
                                                <Field name="mi" className={"form-input"} placeholder="Middle Initial" />
                                                <ErrorMessage name="mi" component="div" className="text-sm text-red-700 pl-4" />
                                            </div>
                                            <div>
                                                <Field name="lastname" className={"form-input"} placeholder="Last Name" />
                                                <ErrorMessage name="lastname" component="div" className="text-sm text-red-700 pl-4" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row gap-5">
                                            <div className="w-full md:w-3/6">
                                                <Field name="email" className="form-input" placeholder="Email" />
                                                <ErrorMessage name="email" component="div" className="text-sm text-red-700 pl-4" />
                                            </div>
                                            <div className="w-full md:w-3/6">
                                                <Field
                                                    name="phone"
                                                    render={({ field }: any) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="phone"
                                                            placeholder="Phone #"
                                                            type="tel"
                                                            className={"form-input"}
                                                            guide={false}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage name="phone" component="div" className="text-sm text-red-700 pl-4" />
                                                <small className="pl-4 italic font-thin">Format: (999) 999-9999</small>
                                            </div>
                                        </div>

                                        <div className="flex flex-col md:flex-row gap-5">
                                            <div className="w-full md:w-3/6">
                                                <Field name="previousEmployerName" className="form-input" placeholder="Previous Employer Name" />
                                                <ErrorMessage name="previousEmployerName" component="div" className="text-sm text-red-700 pl-4" />
                                            </div>
                                            <div className="w-full md:w-3/6">
                                                <Field
                                                    name="previousEmployerPhone"
                                                    render={({ field }: any) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="phone2"
                                                            placeholder="Phone #"
                                                            type="tel"
                                                            className={"form-input"}
                                                            guide={false}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage name="previousEmployerPhone" component="div" className="text-sm text-red-700 pl-4" />
                                                <small className="pl-4 italic font-thin">Format: (999) 999-9999</small>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row gap-5 h-24">
                                            <div className="w-full md:w-3/6">
                                                <Field
                                                    as="select"
                                                    name="englishProficiency"
                                                    className="form-input"
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        setFieldValue('englishProficiency', e.target.value);
                                                        setProficiency(proficiencyText[e.target.selectedIndex - 1]);
                                                    }}
                                                >
                                                    <option value="" disabled selected hidden>English Proficiency</option>
                                                    <option className="text-black" value="Proficient">Proficient (80% - 100%)</option>
                                                    <option className="text-black" value="Advanced">Advanced (60% - 80%)</option>
                                                    <option className="text-black" value="Moderate">Moderate (40% - 60%)</option>
                                                    <option className="text-black" value="Limited">Limited (0% - 40%)</option>
                                                </Field>
                                                <div className="md:mt-2 font-thin italic text-xs">{proficiency}</div>
                                                <ErrorMessage name="englishProficiency" component="div" className="text-sm text-red-700 mt-2" />
                                            </div>
                                            <div className="w-full md:w-3/6">
                                                <Field
                                                    as="select"
                                                    name="levelOSHA"
                                                    className="form-input"
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        setFieldValue('levelOSHA', e.target.value);
                                                        setProficiency(proficiencyText[e.target.selectedIndex]);
                                                    }}
                                                >
                                                    <option value="" disabled selected hidden>OSHA Trainings Complete</option>
                                                    <option className="text-black" value="Basic">Introduction to OSHA</option>
                                                    <option className="text-black" value="Advanced">OSHA 10 hour or greater</option>
                                                    <option className="text-black" value="None">No professional safety training</option>
                                                </Field>
                                                <ErrorMessage name="levelOSHA" component="div" className="text-sm text-red-700 mt-2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-2/4">
                                        <div className="mb-4 mt-4 md:mt-0">
                                            <div className="font-thin tracking-wide">WORK EXPERIENCE</div>
                                            <Field name="workExperience" className="w-full bg-gray-300 focus:outline-none p-2" as="textarea" rows={4} />
                                            <ErrorMessage name="workExperience" component="div" className="text-sm text-red-700 " />
                                        </div>
                                        <div className="mb-2">
                                            <div className="font-thin tracking-wide">KNOWLEDGE, SKILLS AND ABILITIES</div>
                                            <Field name="workKnowledge" className="w-full bg-gray-300 focus:outline-none p-2" as="textarea" rows={4}/>
                                            <ErrorMessage name="workKnowledge" component="div"className="text-sm text-red-700 " />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="flex flex-col md:flex-row mt-2 md:mt-1 md:items-center">
                                        <span className="mb-1 text-sm">
                                            Do you have experience with and own the carpentry tools commonly used in the industry (Bags, Hand Tools, Harness, Lanyard)?
                                        </span>
                                        <div className="flex gap-4 md:ml-8 mr-8">
                                            <label className="flex items-center"><Field type="radio" name="hasOwnTools" value="true" className="mr-2" />Yes</label>
                                            <label className="flex items-center"><Field type="radio" name="hasOwnTools" value="false" className="mr-2" />No</label>
                                        </div>
                                        <ErrorMessage name="hasOwnTools" component="div" className="text-sm text-red-700 " />
                                    </label>
                                    <label className="flex flex-col md:flex-row mt-2 md:mt-1 md:items-center">
                                        <span className="mb-1 text-sm">
                                            Do you have, or are willing to obtain steel toe boots?
                                        </span>
                                        <div className="flex gap-4 md:ml-8 mr-8">
                                            <label className="flex items-center"><Field type="radio" name="hasSteelBoots" value="true" className="mr-2" />Yes</label>
                                            <label className="flex items-center"><Field type="radio" name="hasSteelBoots" value="false" className="mr-2" />No</label>
                                        </div>
                                        <ErrorMessage name="hasSteelBoots" component="div" className="text-sm text-red-700 " />
                                    </label>
                                    <label className="flex flex-col md:flex-row mt-2 md:mt-1 md:items-center">
                                        <span className="mb-1 text-sm">
                                            Do you meet the required minimum for this job posting?
                                        </span>
                                        <div className="flex gap-4 md:ml-8 mr-8">
                                            <label className="flex items-center"><Field type="radio" name="meetMinimumRequired" value="true" className="mr-2" />Yes</label>
                                            <label className="flex items-center"><Field type="radio" name="meetMinimumRequired" value="false" className="mr-2" />No</label>
                                        </div>
                                        <ErrorMessage name="meetMinimumRequired" component="div" className="text-sm text-red-700 " />
                                    </label>
                                    <label className="flex flex-col md:flex-row mt-2 md:mt-1 md:items-center">
                                        <span className="mb-1 text-sm">
                                            Are you at least 18 years of age?
                                        </span>
                                        <div className="flex gap-4 md:ml-8 mr-8">
                                            <label className="flex items-center"><Field type="radio" name="atLeast18" value="true" className="mr-2" />Yes</label>
                                            <label className="flex items-center"><Field type="radio" name="atLeast18" value="false" className="mr-2" />No</label>
                                        </div>
                                        <ErrorMessage name="atLeast18" component="div" className="text-sm text-red-700" />
                                    </label>
                                </div>
                                <div className="flex justify-center mt-10 mb-4">
                                    <button
                                        className="rounded-xl bg-white py-1 px-14 text-fu-dark-gray transition hover:opacity-80 shadow-2xl"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </section>
        </Modal>
    );
};

export default ApplicantForm;
