import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Loading from "../../core/components/Loading";
import { GET_JOBS } from "../../core/graphql/job.graphql";
import { IJob } from "../../core/interfaces/job.interface";
import hammerWideImage from "./../../assets/images/hammer-wide.png";
import AllPositions from "./components/AllPositions";
import HiringNow from "./components/HiringNow";
import "./index.scss";

const ApplicantsPage = () => {
    const [pageSelected, setPageSelected] = useState(1);
    const [jobs, setJobs] = useState<IJob[]>([]);
    const [jobsForAllPosition, setJobsForAllPosition] = useState<IJob[]>([]);

    // QUERYS
    const { data, loading } = useQuery(GET_JOBS, { variables: { input: { args: { branch: null }} }});

    useEffect(() => {
        if (data) {
            const jobs: IJob[] = data.Jobs?.data;
            if (jobs && jobs.length) {
                setJobsForAllPosition(jobs);
                const arr = jobs.filter((job) => job.hiring === true);
                setJobs(arr);
            }
        }
    }, [data]);

    return (
        <div>
            <section
                className={"bg-cover bg-no-repeat bg-center relative"}
                style={{
                    backgroundImage: `url(${hammerWideImage})`,
                    height: "calc(50vh - 67px)",
                }}
            >
                <div
                    className={
                        "bg-white bg-opacity-40 absolute w-full h-full left-0 top-0 grid place-content-center text-center "
                    }
                >
                    <div
                        className={
                            "text-white text-3xl md:text-6xl uppercase font-Roboto font-extrabold tracking-wider"
                        }
                    >
                        Applicants
                    </div>
                </div>
            </section>
            <section className="mb-20 main__container container mx-auto ">
                <div className="tab__container flex flex-wrap mb-px">
                    <button
                        className={`${
                            pageSelected === 1 ? "active" : ""
                        } button_tab`}
                        onClick={() => setPageSelected(1)}
                    >
                        Hiring Now
                    </button>
                    <button
                        className={`${
                            pageSelected === 2 ? "active" : ""
                        } button_tab`}
                        onClick={() => setPageSelected(2)}
                    >
                        All Positions
                    </button>
                </div>

                {loading ? (
                    <Loading />
                ) : pageSelected === 1 ? (
                    <HiringNow jobs={jobs} />
                ) : (
                    <AllPositions jobs={jobsForAllPosition} />
                )}
            </section>
        </div>
    );
};

export default ApplicantsPage;
